import * as R from 'ramda';

const TRADITIONAL_CARD_NUMBER_LENGTH = 16;

const splitEvery4 = (str: string) => R.splitEvery(4, str);

const formatFirst16 = (firstPart: string, rest: string) =>
  R.pipe(splitEvery4, R.join(' '), R.concat(R.__, rest))(firstPart);

const formatCreditCardNumber = (cardNumberLength: number) =>
  R.pipe(
    R.replace(/\s+/g, ''),
    R.splitAt(cardNumberLength),
    R.converge(formatFirst16, [R.head, R.last])
  );

export const formatCreditCardNumber16 = formatCreditCardNumber(
  TRADITIONAL_CARD_NUMBER_LENGTH
);
