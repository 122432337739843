import { AxiosResponseTransformer } from 'axios';

import { camelize } from 'utils/object';

export enum ContentType {
  json = 'application/json',
}

export function getResponseCamelization(
  blacklists?: string[][]
): AxiosResponseTransformer {
  return function (data, headers) {
    if (
      headers != null &&
      headers['content-type'] != null &&
      headers['content-type'] === ContentType.json
    ) {
      let jsonResp;

      try {
        jsonResp = JSON.parse(data);
      } catch (e) {
        return {};
      }

      return camelize(jsonResp, blacklists);
    }

    return data;
  };
}
