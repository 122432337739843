import { factory, getAuthorization } from 'api/base';

import { RequestMethod } from 'utils/constants';

export enum BankCactusPaymentStatus {
  SUCCESS = 1,
  FAILED = 2,
  IN_PROGRESS = 3,
}

export interface IBanksCactusPaymentSession {
  amount: string;
  bankNames: string[];
  bankSent: false;
  card?: string;
  cardHolder?: string;
  currency: {
    alias: string;
    code: number;
  };
  description: string;
  failedUrl: string;
  lng: string;
  paymentId: string;
  status?: BankCactusPaymentStatus;
  successUrl: string;
}

export const getBanksCactusPaySession = factory(
  (r) => (session: string) =>
    r<IBanksCactusPaymentSession>(
      RequestMethod.get,
      `/cactus_v2/session`,
      undefined,
      {
        headers: getAuthorization(session),
      }
    )
);

export interface IBankCactusPaymentDetails {
  card: string;
  cardHolder: string;
  status: number;
}

export const updateBanksCactusPaySession = factory(
  (r) => (session: string, bank: string) =>
    r<IBanksCactusPaymentSession>(
      RequestMethod.post,
      `/cactus_v2/session`,
      {
        bank_name: bank,
      },
      {
        headers: getAuthorization(session),
      }
    )
);
