import React from 'react';

import { useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import * as Styled from './index.styled';

const ContactSupport = () => {
  const { t } = useTranslation('common');
  const { icons } = useTheme();
  // const isDesktop = useMediaQuery(getMedia('md', 'min'));

  return (
    <Styled.ContactSupportBlock>
      <Styled.MediaContent>
        <icons.support.component />
        <Styled.TextContent>
          <p>{t('common:contactSupport.text')}</p>
          <span>{t('common:contactSupport.subText')}</span>
        </Styled.TextContent>
      </Styled.MediaContent>
      {/*TODO: Should be added with the param for returnUrl*/}
      {/*<Button fullWidth variant={isDesktop ? "outlined" : "text"}>*/}
      {/*  {t('common:returnButton')}*/}
      {/*</Button>*/}
    </Styled.ContactSupportBlock>
  );
};

export default ContactSupport;
