import React from 'react';

import { Card, DialogSelect, Input, List } from '@involve-software/uikit';

import styled, { css } from 'styled-components';

import { down } from 'utils/breakpoints';

export const PaymentDetails = styled(Card)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.primitives['s-28']};
  position: relative;
  overflow: hidden;
`;

export const DetailsContent = styled(Card.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font: ${({ theme }) => theme.typography['label-medium']};
  padding-top: ${({ theme }) => theme.spacing.primitives['s-08']};
`;

export const PaymentDetailsForm = styled.form`
  max-width: 280px;
  width: 100%;
`;

export const DetailsList = styled(List)`
  width: 100%;

  & > ul {
    gap: ${({ theme }) => theme.spacing.primitives['s-24']};
  }
`;

export const PayMethodContent = styled(List.Content)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives['s-12']};

  ${down('md')} {
    gap: ${({ theme }) => theme.spacing.primitives['s-04']};
  }
`;

export const PayMethodIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }
`;

export const PayMethodItem = styled(List.Item)`
  ${down('md')} {
    align-items: flex-start;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.primitives['s-08']};
  }
`;

const mobileLabelCss = css`
  font: ${({ theme }) => theme.typography['label-medium']};
  color: ${({ theme }) => theme.colors['text-subtle']};
`;

export const PayMethodLabel = styled.span`
  ${mobileLabelCss};
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
export const InputContent = styled(({ $decorationLabel, ...props }) => (
  <List.Content {...props} />
))`
  width: 100%;

  ${down('md')} {
    > div > span:first-child {
      ${mobileLabelCss};
    }
  }

  ${({ $decorationLabel }) =>
    $decorationLabel &&
    css`
      > div > div {
        &::after {
          content: '${$decorationLabel}';
          white-space: nowrap;
          position: absolute;
          color: ${({ theme }) => theme.colors['text-subtle']};
          transform: translate(-100%, 0);
          left: -${({ theme }) => theme.spacing.primitives['s-28']};
        }
      }
    `}
`;

const inputCss = css`
  input {
    font-family: ${({ theme }) => theme.typography.family};
    font: ${({ theme }) => theme.typography['paragraph-medium']};
  }
`;

export const BlockchainSelect = styled(DialogSelect)`
  ${inputCss};

  input,
  button {
    pointer-events: none;
  }
`;

export const BlockchainValue = styled.span`
  font: ${({ theme }) => theme.typography['paragraph-medium']};
  color: ${({ theme }) => theme.colors['text-primary']};
`;

export const EmailInput = styled(Input)`
  ${inputCss};
`;

export const Confirmation = styled.div`
  max-width: 240px;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.primitives['s-32']} auto auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-20']};
`;
