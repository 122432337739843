import styled from 'styled-components/macro';
import { down } from 'utils/breakpoints';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors['background-01']};
  background-image: ${({ theme }) =>
    theme.images.mainBg ? `url(${theme.images.mainBg.path})` : 'none'};
  background-repeat: no-repeat;
  background-position: 100% 0px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-16']};

  ${down('sm')} {
    background-image: none;
    background-color: #ffffff;
  }
`;

export const Content = styled.div`
  margin: ${({ theme }) => theme.spacing.primitives['s-12']} 0 72px;
  max-width: 762px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
