import React from 'react';

import * as Styled from './index.styled';

const InputsLoadingSkeleton = () => (
  <>
    <Styled.Item>
      <Styled.StartItemLabel>
        <Styled.LoadingSkeleton />
      </Styled.StartItemLabel>
      <Styled.InputContent>
        <Styled.LoadingSkeleton />
      </Styled.InputContent>
    </Styled.Item>
    <Styled.Item>
      <Styled.StartItemLabel>
        <Styled.LoadingSkeleton />
      </Styled.StartItemLabel>
      <Styled.InputContent>
        <Styled.LoadingSkeleton />
      </Styled.InputContent>
    </Styled.Item>
  </>
);

export default InputsLoadingSkeleton;
