import { Button, Card, Link } from '@involve-software/uikit';

import styled from 'styled-components';

import { down } from 'utils/breakpoints';

export const QrCard = styled(Card)`
  padding-top: ${({ theme }) => theme.spacing.primitives['s-24']};
  padding-bottom: ${({ theme }) => theme.spacing.primitives['s-36']};
  width: 100%;

  ${down('md')} {
    padding-bottom: ${({ theme }) => theme.spacing.primitives['s-48']};
  }
`;

export const QrHeading = styled(Card.Heading)`
  align-items: center;
  margin: 0;
  padding-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};

  ${down('md')} {
    border: none;
  }
`;

export const QrTitle = styled(Card.Title)`
  font-size: 18px;

  ${down('md')} {
    font-size: 16px;
  }
`;

export const QrHeadingActions = styled(Card.HeadingActions)`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-04']};
`;

export const Amount = styled.h3`
  font: ${({ theme }) => theme.typography['heading-large']};
  color: ${({ theme }) => theme.colors['text-primary']};
  margin: 0;
`;

export const AmountLabel = styled.span`
  font: ${({ theme }) => theme.typography['label-medium']};
  color: ${({ theme }) => theme.colors['text-subtle']};
`;

export const QrMethodLogo = styled.div`
  margin: ${({ theme }) => theme.spacing.primitives['s-24']} auto;
  width: fit-content;
`;

export const QrCode = styled.div`
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives['s-12']};

  p {
    margin: 0;
    text-align: center;
    line-height: 20px;
  }

  img {
    max-width: 200px;
    max-height: 200px;
    width: 100%;
    height: 100%;
  }
`;

export const SuccessLink = styled(Link)`
  text-decoration: none;
`;

export const SuccessButton = styled(Button)`
  margin: 0 auto;
  min-width: 200px;
  margin-top: ${({ theme }) => theme.spacing.primitives['s-24']};
`;
