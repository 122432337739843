import React from 'react';

import { Button, useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';
import { Link, useLoaderData } from 'react-router-dom';

import { ICardTransferSession } from 'api/payment/cardTransfer';

import * as Styled from './index.styled';

const ExpiryStatusBlock = () => {
  const { images } = useTheme();
  const { t } = useTranslation('cardTransfer');

  const { returnUrl } = useLoaderData() as ICardTransferSession;

  return (
    <Styled.ExpiryStatusBlock>
      <images.statuses.timeout.component />
      <p>{t('cardTransfer:timeout')}</p>
      {returnUrl && (
        <Link to={returnUrl}>
          <Button fullWidth variant="outlined">
            {t('cardTransfer:returnButton')}
          </Button>
        </Link>
      )}
    </Styled.ExpiryStatusBlock>
  );
};

export default ExpiryStatusBlock;
