import { Button, List } from '@involve-software/uikit';

import styled from 'styled-components';

import { down } from 'utils/breakpoints';

export const Details = styled.div`
  flex: 0.55;
  max-width: 55%;

  ${down('md')} {
    flex: 1;
    max-width: 100%;
  }
`;

export const Title = styled.span`
  font: ${({ theme }) => theme.typography['heading-small']};
`;

export const DetailsList = styled(List)`
  padding: ${({ theme }) => theme.spacing.primitives['s-20']} 0;
  font: ${({ theme }) => theme.typography['label-medium']};
  white-space: nowrap;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  width: 100%;
  overflow: hidden;

  > ul {
    gap: ${({ theme }) => theme.spacing.primitives['s-24']};
  }

  ${down('md')} {
    > ul {
      font-size: 12px;
      gap: ${({ theme }) => theme.spacing.primitives['s-12']};
    }
  }

  ${down('sm')} {
    > ul > li {
      flex-direction: row;
    }
  }
`;

export const ItemContent = styled(List.Content)`
  font: ${({ theme }) => theme.typography['label-medium']};
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;

  ${down('md')} {
    font-size: 12px;
  }
`;

export const ItemLabel = styled(List.Label)`
  min-width: auto;
`;

export const PaymentMethodContent = styled(ItemContent)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.primitives['s-04']};
  position: relative;
  overflow: visible;
`;

export const PayMethodIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }

  ${down('md')} {
    img {
      width: 24px;
      height: 24px;
    }

    left: -${({ theme }) => theme.spacing.primitives['s-28']};
  }
`;

export const BlockchainDetails = styled.span`
  font: ${({ theme }) => theme.typography['label-medium']};
  color: ${({ theme }) => theme.colors['text-subtle']};
`;

export const AddressDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-24']};
`;

export const AddressInfo = styled.div`
  margin-top: ${({ theme }) => theme.spacing.primitives['s-20']};
`;

export const MinDeposit = styled.div`
  font: ${({ theme }) => theme.typography['label-x-small']};
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-08']};

  ${down('md')} {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-12']};
`;

export const ActionButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing.primitives['s-12']}
    ${({ theme }) => theme.spacing.primitives['s-16']};
`;
