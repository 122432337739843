import { type LoaderFunction } from 'react-router-dom';

import { getCardTransferSession } from 'api/payment/cardTransfer';

export const cardTransferLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const { data } = await getCardTransferSession(params.paymentId);

  return data;
};
