import { type LoaderFunction } from 'react-router-dom';

import { getCactusPaymentSession } from 'api/payment/cactus';

export const cactusPaymentLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const { data } = await getCactusPaymentSession(params.paymentId);

  return data;
};
