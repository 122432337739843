import { useContext } from 'react';

import {
  SnackbarContext,
  type ToggleSnackbar,
} from 'components/Providers/Snackbar';

export const useToggleSnackbar = () => {
  const { toggleSnackbar } = useContext(SnackbarContext);
  return toggleSnackbar as ToggleSnackbar;
};
