import { Dialog } from '@involve-software/uikit';

import styled from 'styled-components';

import { down } from 'utils/breakpoints';

export const QrPayDialog = styled(Dialog)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.primitives['s-24']};

  ${down('md')} {
     {
      /* TODO: Can be handled better on UIKit level */
    }
    width: 90%;
    height: 90%;
    overflow-y: auto;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-04']};
`;

export const Text = styled.div`
  font: ${({ theme }) => theme.typography['paragraph-medium']};
   {
    /* TODO: Update color in UIKit to follow design system */
  }
  color: ${({ theme }) => theme.colors['text-subtle']};
`;

export const Description = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing.primitives['s-08']};
  display: flex;
  align-self: flex-start;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.primitives['s-28']};

  ul {
    font-size: 13px;
    margin: 0;
    padding-left: ${({ theme }) => theme.spacing.primitives['s-20']};
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.primitives['s-08']};
  }
`;
