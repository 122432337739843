import { type LoaderFunction } from 'react-router-dom';

import { getMbankTransferSession } from 'api/payment/mbankTransfer';

export const mbankTransferLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const { data } = await getMbankTransferSession(params.paymentId);

  return data;
};
