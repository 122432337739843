import { Card } from '@involve-software/uikit';

import { Link } from 'react-router-dom';

import styled from 'styled-components/macro';
import { down } from 'utils/breakpoints';

export const Title = styled(Card.Title)`
  font-size: 18px;
  margin-top: 11px;
`;

export const BanksSelectionCard = styled(Card)`
  width: 100%;
`;

export const BankOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 20px;

  ${down('md')} {
    grid-template-columns: repeat(3, 1fr);
    gap: ${({ theme }) => theme.spacing.primitives['s-12']};
  }

  ${down('sm')} {
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => theme.spacing.primitives['s-12']};
  }
`;

export const ReturnLink = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-decoration: none;
`;
