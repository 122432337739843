import React from 'react';

import { Button } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import SuccessStatusLabel from 'modules/result/components/StatusLabels/Success';

import { LinkButton } from '../index.styled';

const CardTransferSuccessStatus = () => {
  const [searchParams] = useSearchParams();

  const returnUrl = searchParams.get('returnUrl');

  const { t } = useTranslation('result');

  return (
    <>
      <SuccessStatusLabel label={t('success.label')} />
      {returnUrl && (
        <LinkButton href={returnUrl}>
          <Button variant="outlined" fullWidth>
            {t('common:returnButton')}
          </Button>
        </LinkButton>
      )}
    </>
  );
};

export default CardTransferSuccessStatus;
