import React, { useContext } from 'react';

import { Card } from '@involve-software/uikit';

import WrongParamsError from 'components/Errors/WrongParams';
import SelfSubmittingForm from 'components/selfSubmittingForm';

import { PaymentContext } from 'modules/payment/layout';

const AutoTypePayment = () => {
  const paymentSession = useContext(PaymentContext);

  const paymentRedirect = paymentSession.extra.redirect;
  if (paymentRedirect == null)
    return (
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    );

  return (
    <SelfSubmittingForm
      method={paymentRedirect.method}
      action={paymentRedirect.url}
      data={paymentRedirect.data}
    />
  );
};

export default AutoTypePayment;
