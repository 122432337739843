import { redirect } from 'react-router-dom';

import { PaymentSessionType } from 'utils/constants';

type TypeValidator = (
  paymentId: string,
  request: Request
) => Response | undefined;

const sessionTypeValidators: Partial<
  Record<PaymentSessionType, TypeValidator>
> = {
  [PaymentSessionType.AUTO]: (paymentId, request) => {
    if (!request.url.endsWith(`/auto`)) {
      return redirect(`/payment/${paymentId}/auto`);
    }
  },
  [PaymentSessionType.PRE]: (paymentId, request) => {
    if (!request.url.endsWith(`/details`)) {
      return redirect(`/payment/${paymentId}/details`);
    }
  },
};

export default sessionTypeValidators;
