import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import { paycosLoader } from './loaders';
import PaycosPayment from './pages/PaycosPayment';

export default (
  <Route
    path="p2p_pcs/:paymentId"
    loader={paycosLoader}
    element={<PaycosPayment />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  />
);
