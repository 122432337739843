import React from 'react';

import * as Styled from './index.styled';

interface OptionCardProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'title'> {
  icon: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const OptionCard: React.FC<OptionCardProps> = ({
  icon,
  title,
  subtitle,
  disabled,
  ...props
}) => (
  <Styled.OptionCard disabled={disabled} {...props}>
    <Styled.OptionIcon disabled={disabled}>{icon}</Styled.OptionIcon>
    {title && (
      <Styled.OptionText>
        <Styled.OptionName disabled={disabled}>{title}</Styled.OptionName>
        {subtitle && (
          <Styled.OptionSubtitle disabled={disabled}>
            {subtitle}
          </Styled.OptionSubtitle>
        )}
      </Styled.OptionText>
    )}
  </Styled.OptionCard>
);

export default OptionCard;
