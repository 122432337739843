import React from 'react';

import { Button, useTheme } from '@involve-software/uikit';

import { Trans, useTranslation } from 'react-i18next';

import CopyBlock from 'components/CopyBlock';

import type { PaycosCardProps } from './index.interface';
import * as Styled from './index.styled';
import { ExpiredAlert } from './index.styled';

const PaycosCard: React.FC<PaycosCardProps> = ({
  card,
  cardHolder,
  returnUrl,
  successUrl,
}) => {
  const { t } = useTranslation('paycos');
  const { icons } = useTheme();

  return (
    <Styled.PaycosCard>
      <Styled.PaycosTitle>{t('paycos:payment')}</Styled.PaycosTitle>
      <Styled.PaycosSubtitle>{t('paycos:paymentGuide')}</Styled.PaycosSubtitle>
      <Styled.PaycosDetails>
        <CopyBlock
          copyText={card}
          label={t('paycos:details')}
          startAdornment={<icons.card.component />}
        />
        <CopyBlock
          copyText={cardHolder}
          label={t('paycos:cardOwner')}
          startAdornment={<icons.profile.component />}
        />

        <ExpiredAlert title={t('common:alerts.important')} variant="warning">
          <Trans i18nKey="paycos:expiredText">
            Payment details are only valid for <b>8 minutes!</b>
          </Trans>
        </ExpiredAlert>

        <Styled.ButtonsGroup>
          {returnUrl && (
            <Styled.ButtonLinkWrap href={returnUrl}>
              <Button variant="outlined">{t('paycos:returnButton')}</Button>
            </Styled.ButtonLinkWrap>
          )}

          {successUrl && (
            <Styled.ButtonLinkWrap href={successUrl}>
              <Button>{t('common:alreadyPaid')}</Button>
            </Styled.ButtonLinkWrap>
          )}
        </Styled.ButtonsGroup>
      </Styled.PaycosDetails>
    </Styled.PaycosCard>
  );
};

export default PaycosCard;
