import React, { FC } from 'react';

import { useTheme } from '@involve-software/uikit';

import { formatTime, timeConvert } from 'utils/time';

import * as Styled from './index.styled';

interface ExpiryTimerProps {
  secondsLeft: number;
  countdown: number;
}

const ExpiryTimer: FC<ExpiryTimerProps> = ({ secondsLeft, countdown }) => {
  const { colors } = useTheme();

  const [hours, minutes, seconds] = formatTime(timeConvert(countdown));

  const timeString = `${hours}:${minutes}:${seconds}`;

  const circleConfig = {
    strokeDasharray: 101,
    strokeDashoffset: 100 - ((secondsLeft - countdown) / secondsLeft) * 100,
  };

  return (
    <Styled.ExpiryTimer>
      {secondsLeft > 0 ? (
        <>
          {timeString}
          <svg width="18" height="18" viewBox="0 0 18 18">
            <circle
              cx="9"
              cy="9"
              r="7"
              fill="none"
              stroke={colors['icon-brand']}
              strokeWidth="3"
            />
            <circle
              cx="9"
              cy="9"
              r="7"
              fill="none"
              stroke={colors['layer-03']}
              strokeDasharray={circleConfig.strokeDasharray}
              strokeDashoffset={circleConfig.strokeDashoffset}
              strokeWidth="4"
              pathLength="100"
            />
          </svg>
        </>
      ) : (
        '00:00:00'
      )}
    </Styled.ExpiryTimer>
  );
};

export default ExpiryTimer;
