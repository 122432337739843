import React from 'react';

import { usePayMethodName } from 'utils/hooks/usePayMethodName';

import * as Styled from './index.styled';

interface MethodOptionProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode;
  name: string;
  nameTranslations: Record<string, string> | null;
  amount: number;
  currency: string;
}

const MethodOption: React.FC<MethodOptionProps> = ({
  icon,
  name,
  nameTranslations,
  amount,
  currency,
  ...props
}) => {
  const payMethodName = usePayMethodName({
    transObj: nameTranslations,
    defaultValue: name,
  });

  return (
    <Styled.MethodOption {...props}>
      <Styled.OptionIcon>{icon}</Styled.OptionIcon>
      <Styled.OptionText>
        <Styled.OptionName>{payMethodName}</Styled.OptionName>
        <Styled.OptionAmount>
          {amount} {currency}
        </Styled.OptionAmount>
      </Styled.OptionText>
    </Styled.MethodOption>
  );
};

export default MethodOption;
