import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import PaymentDetailsLayout from 'modules/payment/pages/details/layout';

import InvoiceStepsLayout from './components/InvoiceStepsLayout';
import PaymentLayout from './layout';
import {
  detailsLoader,
  methodsLoader,
  paymentLoader,
  redirectLoader,
} from './loaders';
import AutoTypePayment from './pages/auto';
import PaymentDetails from './pages/details';
import PaymentMethods from './pages/methods';

export default (
  <Route
    path="payment/:paymentId"
    loader={paymentLoader}
    element={<PaymentLayout />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  >
    <Route path="" loader={redirectLoader} />
    <Route path="auto" element={<AutoTypePayment />} />
    <Route element={<InvoiceStepsLayout />} errorElement={<WrongParamsError />}>
      <Route
        path="methods"
        loader={methodsLoader}
        element={<PaymentMethods />}
      />
      <Route
        path="details"
        loader={detailsLoader}
        element={<PaymentDetails />}
        errorElement={
          <PaymentDetailsLayout>
            <WrongParamsError />
          </PaymentDetailsLayout>
        }
      />
    </Route>
  </Route>
);
