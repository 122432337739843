import React from 'react';

import { Card } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import * as Styled from './index.styled';

interface ErrorPageProps extends React.PropsWithChildren {
  label?: string;
}

const ErrorCard: React.FC<ErrorPageProps> = ({ label, children }) => {
  const { t } = useTranslation();

  return (
    <Card style={{ width: '100%' }}>
      <Styled.Error>
        <Styled.StatusLabel label={label ?? t('common:error')} />
        {children}
      </Styled.Error>
    </Card>
  );
};

export default ErrorCard;
