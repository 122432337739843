import React from 'react';

import { Button, Card } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useNavigation,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';

import type { IBanksCactusPaymentSession } from 'api/payment/banks';

import LoadingOverlay from 'components/LoadingOverlay';
import OptionCard from 'components/OptionCard';

import { BANKS_ICONS } from 'data/icons/banks';

import * as Styled from './index.styled';

const CactusBanksSelection = () => {
  const { paymentId } = useParams();

  const navigate = useNavigate();
  const navigation = useNavigation();

  const { bankNames, successUrl } = useRouteLoaderData(
    'cactusBanks'
  ) as IBanksCactusPaymentSession;

  const { t } = useTranslation('cactusBanks');

  return (
    <Styled.BanksSelectionCard>
      {navigation.state === 'loading' && <LoadingOverlay />}
      <Card.Heading>
        <Card.HeadingTitle>
          <Styled.Title>{t('banks.title')}</Styled.Title>
        </Card.HeadingTitle>
      </Card.Heading>
      <Card.Content $dividerDisabled>
        <Styled.BankOptions>
          {bankNames.map((name) => (
            <OptionCard
              key={name}
              title={name}
              icon={BANKS_ICONS[name]}
              onClick={() =>
                navigate({
                  pathname: `/cactus_v2/${paymentId}/payment`,
                  search: `?bank=${name}`,
                })
              }
            />
          ))}
        </Styled.BankOptions>
        {successUrl && (
          <Styled.ReturnLink to={successUrl} target="_blank">
            <Button variant="outlined">{t('cactusBanks:returnButton')}</Button>
          </Styled.ReturnLink>
        )}
      </Card.Content>
    </Styled.BanksSelectionCard>
  );
};

export default CactusBanksSelection;
