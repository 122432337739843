import { useEffect } from 'react';

import nprogress from 'nprogress';
import { useNavigation } from 'react-router-dom';

import 'nprogress/nprogress.css';

const useNavigationLoadingBar = () => {
  const { state } = useNavigation();

  useEffect(() => {
    if (state === 'loading') {
      nprogress.start();

      return () => {
        nprogress.done();
      };
    }
  }, [state]);
};

export default useNavigationLoadingBar;
