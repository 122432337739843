import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import InvoiceCard from 'modules/payment/components/InvoiceCard';
import { fiatCurrencyAlias } from 'modules/payment/data/currency';
import { PAGE_STEP_INDEX } from 'modules/payment/data/steps';
import { PaymentContext } from 'modules/payment/layout';

import { getMedia, useMediaQuery } from 'utils/breakpoints';
import { getLastUrlSegment } from 'utils/navigation';

import Stepper from '../Stepper';

import * as Styled from './index.styled';

const InvoiceStepsLayout: React.FC = () => {
  const isDesktop = useMediaQuery(getMedia('md', 'min'));

  const session = useContext(PaymentContext);

  const { pathname } = useLocation();
  const stepPage = getLastUrlSegment(pathname);
  const step = PAGE_STEP_INDEX[(stepPage as NonNullable<typeof stepPage>)[1]];

  const { t } = useTranslation('payment');

  return (
    <Styled.StepsLayout>
      <InvoiceCard
        // TODO: Determine which property should be used for id
        id={session.shopOrderId}
        sum={session.amount}
        currency={fiatCurrencyAlias[session.currency]}
        reciever={session.shopName}
        description={session.description}
      />
      <Styled.StepCard>
        <Styled.Content $dividerDisabled>
          {isDesktop && (
            <Stepper activeStep={step}>
              <Stepper.Step $label={t('stepper.methods')} />
              <Stepper.Step $label={t('stepper.details')} />
              <Stepper.Step $label={t('stepper.payment')} />
            </Stepper>
          )}
          <Outlet />
        </Styled.Content>
      </Styled.StepCard>
    </Styled.StepsLayout>
  );
};

export default InvoiceStepsLayout;
