import React from 'react';

import { useLoaderData } from 'react-router-dom';

import { ICactusPaymentSession } from 'api/payment/cactus';

import CactusPaymentCard from './components/CactusPaymentCard';
import InvoiceCard from './components/InvoiceCard';
import * as Styled from './index.styled';

const CactusPayment = () => {
  const {
    amount,
    card,
    cardHolder,
    currency,
    description,
    returnUrl,
    successUrl,
    paymentId,
  } = useLoaderData() as ICactusPaymentSession;

  return (
    <Styled.CactusPayment>
      <InvoiceCard
        id={paymentId}
        sum={amount}
        currency={currency.alias}
        description={description}
      />
      <CactusPaymentCard
        returnUrl={returnUrl}
        successUrl={successUrl}
        card={card}
        cardHolder={cardHolder}
      />
    </Styled.CactusPayment>
  );
};

export default CactusPayment;
