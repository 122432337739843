import { type LoaderFunction } from 'react-router-dom';

import { getPlatipaySession } from 'api/payment/platipay';

export const platipayLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const { data } = await getPlatipaySession(params.paymentId);

  return data;
};
