import { type LoaderFunction } from 'react-router-dom';

import type { Language } from 'data/i18n';
import { changeLanguage } from 'utils/language';

import { REQUIRED_BINANCEPAY_PARAMS } from './data/params';

export const binancePayLoader: LoaderFunction = async ({ request }) => {
  const { searchParams } = new URL(request.url);

  if (REQUIRED_BINANCEPAY_PARAMS.some((param) => !searchParams.has(param))) {
    throw new Error('Required params are missing');
  }

  if (searchParams.get('lng')) {
    await changeLanguage(searchParams.get('lng') as Language);
  }
  return null;
};
