import React from 'react';

import { useTranslation } from 'react-i18next';

import type { DetailsData } from 'modules/crypto/data/loader-data';

import type { Language } from 'data/i18n';

import ManualAlert from './ManualAlert';
import * as Styled from './index.styled';

interface SideInfoSectionProps {
  infoText: DetailsData['infoText'];
}

const SideInfoSection: React.FC<SideInfoSectionProps> = ({ infoText }) => {
  const { t, i18n } = useTranslation('crypto');

  const language = i18n.language as Language;

  return (
    <Styled.SideInfo>
      {infoText.manual?.text[language] && (
        <ManualAlert
          manualInfo={{
            name: infoText.manual.name,
            text: infoText.manual.text,
          }}
        />
      )}
      {infoText.warning?.text[language] && (
        <Styled.InfoAlert
          title={t('common:alerts.important')}
          variant="warning"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: infoText.warning.text[language],
            }}
          />
        </Styled.InfoAlert>
      )}
      {infoText.info?.text[language] && (
        <Styled.InfoAlert title={t('common:alerts.info')} variant="info">
          <div
            dangerouslySetInnerHTML={{
              __html: infoText.info.text[language],
            }}
          />
        </Styled.InfoAlert>
      )}
    </Styled.SideInfo>
  );
};

export default SideInfoSection;
