import React from 'react';

import { Button, useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import { ICactusPaymentSession } from 'api/payment/cactus';

import CopyBlock from 'components/CopyBlock';

import * as Styled from './index.styled';

const CactusPaymentCard: React.FC<
  Pick<
    ICactusPaymentSession,
    'card' | 'cardHolder' | 'returnUrl' | 'successUrl'
  >
> = ({ card, cardHolder, returnUrl, successUrl }) => {
  const { t } = useTranslation('cactusPay');
  const { icons } = useTheme();

  return (
    <Styled.CactusCard>
      <Styled.CactusTitle>{t('cactusPay:payment')}</Styled.CactusTitle>
      <Styled.CactusSubtitle>
        {t('cactusPay:paymentGuide')}
      </Styled.CactusSubtitle>
      <Styled.CactusDetails>
        <CopyBlock
          copyText={card}
          label={t('cactusPay:details')}
          startAdornment={<icons.card.component />}
        />
        <CopyBlock
          copyText={cardHolder}
          label={t('cactusPay:cardOwner')}
          startAdornment={<icons.profile.component />}
        />

        <Styled.ButtonsGroup>
          {returnUrl && (
            <Styled.ButtonLinkWrap href={returnUrl}>
              <Button variant="outlined">{t('cactusPay:returnButton')}</Button>
            </Styled.ButtonLinkWrap>
          )}
          {successUrl && (
            <Styled.ButtonLinkWrap href={successUrl}>
              <Button>{t('common:alreadyPaid')}</Button>
            </Styled.ButtonLinkWrap>
          )}
        </Styled.ButtonsGroup>
      </Styled.CactusDetails>
    </Styled.CactusCard>
  );
};

export default CactusPaymentCard;
