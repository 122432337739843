import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import ContactSupport from './pages/ContactSupport';

export default (
  <Route
    path="contact_support"
    element={<ContactSupport />}
    errorElement={
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  />
);
