import React, { useState } from 'react';

import { useTheme } from '@involve-software/uikit';

import * as Styled from './index.styled';

interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ label, children, ...props }) => {
  const [open, setOpen] = useState(false);
  const { icons } = useTheme();

  return (
    <Styled.Accordion {...props}>
      <Styled.ActionButton $open={open} onClick={() => setOpen(!open)}>
        {label}
        <Styled.ActionIcon $open={open}>
          <icons.actions.arrowDown.component />
        </Styled.ActionIcon>
      </Styled.ActionButton>
      {open && <Styled.Content>{children}</Styled.Content>}
    </Styled.Accordion>
  );
};

export default Accordion;
