import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import { binancePayLoader } from './loaders';
import BinancePay from './pages/BinancePay';

export default (
  <Route
    path="binancepay"
    loader={binancePayLoader}
    element={<BinancePay />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  />
);
