import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import { emanatLoader } from './loaders';
import EmanatPayment from './pages/EmanatPayment';

export default (
  <Route
    path="p2p_emanat/:paymentId"
    loader={emanatLoader}
    element={<EmanatPayment />}
    errorElement={
      // TODO: Add fullWidth prop to Card in UIKit
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  />
);
