import { Card } from '@involve-software/uikit';

import { Link } from 'react-router-dom';

import { OptionCard, OptionName } from 'components/OptionCard/index.styled';

import styled from 'styled-components/macro';
import { down } from 'utils/breakpoints';

export const Title = styled.div`
  font: ${({ theme }) => theme.typography['heading-small']};
`;

export const PaymentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-24']};
  padding-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};
  border-bottom: 2px solid ${({ theme }) => theme.colors['border-subtle-01']};
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-24']};
`;

export const PaymentAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.primitives['s-08']};
  & > p {
    margin: 0;
    &:nth-child(1) {
      font: ${({ theme }) => theme.typography['label-medium']};
      color: ${({ theme }) => theme.colors['text-secondary']};
      ${down('md')} {
        font: ${({ theme }) => theme.typography['label-small']};
      }
    }
    &:nth-last-child(1) {
      font: ${({ theme }) => theme.typography['heading-large']};
      color: ${({ theme }) => theme.colors['text-primary']};
      ${down('md')} {
        font: ${({ theme }) => theme.typography['heading-x-small']};
      }
    }
  }
`;

export const MethodSelectionCard = styled(Card)`
  width: 100%;
  ${down('md')} {
    padding: ${({ theme }) => theme.spacing.primitives['s-28']}
      ${({ theme }) => theme.spacing.primitives['s-16']};
  }
`;

export const MethodOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 160px);
  grid-column-gap: ${({ theme }) => theme.spacing.primitives['s-12']};
  grid-row-gap: ${({ theme }) => theme.spacing.primitives['s-12']};
  ${down('md')} {
    grid-template-columns: minmax(auto, 1fr);
    gap: ${({ theme }) => theme.spacing.primitives['s-08']};
  }

  ${OptionCard} {
    flex-basis: 156px;
    height: 156px;
    background: transparent;
    gap: ${({ theme }) => theme.spacing.primitives['s-12']};
    transition: 0.3s ease-in-out all;
    &:hover {
      background-color: ${({ theme }) => theme.colors['card-01']};
    }
    &:active {
      background: transparent;
      border-color: ${({ theme }) => theme.colors['border-brand']};
    }
    ${OptionName} {
      font: ${({ theme }) => theme.typography['label-small']};
    }
    ${down('md')} {
      padding: ${({ theme }) => theme.spacing.primitives['s-08']}
        ${({ theme }) => theme.spacing.primitives['s-16']};
      height: 56px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
`;

export const ReturnLink = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.primitives['s-20']};
  text-decoration: none;
  ${down('md')} {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
