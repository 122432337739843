import i18next, { type i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { AVAILABLE_LNGS, FALLBACK_LNG } from 'data/i18n';

import { env } from './env';

let i18nInstance: i18n;

const I18nInstanceSetter = {
  type: '3rdParty' as const,
  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };

export default i18next
  .use(HttpApi)
  .use(I18nInstanceSetter)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: FALLBACK_LNG,
    supportedLngs: AVAILABLE_LNGS,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: {
        v:
          process.env.NODE_ENV === 'production'
            ? process.env.BUILD_VERSION
            : 'dev',
      },
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'lng',
      lookupCookie: 'lng',
      lookupQuerystring: 'lng',
      cookieMinutes: 525600, // 1 year
      cookieDomain: `.${env.REACT_APP_BASE_DOMAIN}`,
    },
    ns: ['common', 'validation'],
    defaultNS: 'common',
    react: { useSuspense: false },
  });
