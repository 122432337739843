import React from 'react';

import { Button, Card } from '@involve-software/uikit';

import { Maybe } from 'monet';
import { __, gt } from 'ramda';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useNavigation,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';

import {
  MethodsList,
  MethodsMap,
  SelectMethodPaySession,
} from 'api/payment/selectMethod';

import LoadingOverlay from 'components/LoadingOverlay';
import OptionCard from 'components/OptionCard';

import { FALLBACK_LNG } from 'data/i18n';

import * as Styled from './index.styled';

// TODO: This is a temporary solution to convert the methods to an array
// remove this when the API is updated
// issue: https://involve-software.atlassian.net/browse/KVITUM-8951
const convertMethodsToArray = (
  methods: MethodsMap | MethodsList
): MethodsList => {
  if (Array.isArray(methods)) return methods;

  return Object.entries(methods).map(([key, value]) => ({
    name: key,
    minLimit: null,
    displayName: value,
  }));
};

const checkIfMethodIsDisabled = (amount: number) => (minLimit: number | null) =>
  Maybe.fromEmpty(minLimit).map(gt(__, amount)).getOrElse(false);

const pickTranslation =
  (currentLanguage: string, defaultLanguage: string) =>
  (obj: { [key: string]: string }) =>
    Maybe.fromEmpty(obj[currentLanguage]).getOrElse(obj[defaultLanguage]);

const SelectMethodSelection = () => {
  const params = useParams();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const { amount, currency, paymentId, methods, returnUrl } =
    useRouteLoaderData('selectMethod') as SelectMethodPaySession;

  const {
    t,
    i18n: { language },
  } = useTranslation('cactusBanks');

  const isMethodDisabled = checkIfMethodIsDisabled(amount);

  const translate = pickTranslation(language, FALLBACK_LNG);

  const formattedMethods = convertMethodsToArray(methods).map((method) => ({
    ...method,
    displayName: translate(method.displayName),
    isDisabled: isMethodDisabled(method.minLimit),
  }));

  return (
    <Styled.MethodSelectionCard>
      {navigation.state === 'loading' && <LoadingOverlay />}
      <Styled.PaymentInfo>
        <Styled.Title>
          {t('common:invoice')} #{paymentId}
        </Styled.Title>

        <Styled.PaymentAmount>
          <p>{t('common:paymentAmount')}</p>
          <p>
            {amount} {currency.alias}
          </p>
        </Styled.PaymentAmount>
      </Styled.PaymentInfo>

      <Card.Heading>
        <Card.HeadingTitle>
          <Styled.Title>{t('selectMethod.title')}</Styled.Title>
        </Card.HeadingTitle>
      </Card.Heading>
      <Card.Content $dividerDisabled>
        <Styled.MethodOptions>
          {formattedMethods.map(
            ({ name, displayName, minLimit, isDisabled }) => (
              <OptionCard
                key={name}
                title={displayName}
                subtitle={
                  isDisabled &&
                  t('availableFrom', {
                    amount: minLimit,
                    currency: currency.alias,
                  })
                }
                icon={<img src={`/p2p_pm_images/${name}.png`} alt="" />}
                disabled={isDisabled}
                onClick={() =>
                  navigate({
                    pathname: `/select_method/${params.paymentId}/payment`,
                    search: `?method=${name}`,
                  })
                }
              />
            )
          )}
        </Styled.MethodOptions>
        {returnUrl && (
          <Styled.ReturnLink to={returnUrl} target="_blank">
            <Button variant="outlined">{t('cactusBanks:returnButton')}</Button>
          </Styled.ReturnLink>
        )}
      </Card.Content>
    </Styled.MethodSelectionCard>
  );
};

export default SelectMethodSelection;
