import React from 'react';

import { useLoaderData } from 'react-router-dom';

import { INcpaySession } from 'api/payment/ncpay';

import InvoiceCard from './components/InvoiceCard';
import NcpayCard from './components/NcpayCard';
import * as Styled from './index.styled';

const NcpayPayment = () => {
  const {
    amount,
    card,
    currency,
    description,
    returnUrl,
    successUrl,
    paymentId,
  } = useLoaderData() as INcpaySession;

  return (
    <Styled.NcpayPayment>
      <InvoiceCard
        id={paymentId}
        sum={amount}
        currency={currency.alias}
        description={description}
      />
      <NcpayCard returnUrl={returnUrl} successUrl={successUrl} card={card} />
    </Styled.NcpayPayment>
  );
};

export default NcpayPayment;
