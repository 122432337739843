import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { Language } from 'data/i18n';

const useLanguageChange = () => {
  const { i18n } = useTranslation();

  const location = useLocation();

  const handleLanguageChange = (lang: Language) => {
    window.history.pushState(
      null,
      '',
      location.pathname.replace(/\/[^/]+/, `/${lang}`) + location.search
    );

    i18n.changeLanguage(lang);
  };

  return handleLanguageChange;
};

export default useLanguageChange;
