import React from 'react';

import { useLoaderData } from 'react-router-dom';

import { IEmanatSession } from 'api/payment/emanat';

import EmanatCard from './components/EmanatCard';
import InvoiceCard from './components/InvoiceCard';
import * as Styled from './index.styled';

const EmanatPayment = () => {
  const {
    amount,
    card,
    cardHolder,
    currency,
    description,
    returnUrl,
    successUrl,
    paymentId,
  } = useLoaderData() as IEmanatSession;

  return (
    <Styled.EmanatPayment>
      <InvoiceCard
        id={paymentId}
        sum={amount}
        currency={currency.alias}
        description={description}
      />
      <EmanatCard
        returnUrl={returnUrl}
        successUrl={successUrl}
        card={card}
        cardHolder={cardHolder}
      />
    </Styled.EmanatPayment>
  );
};

export default EmanatPayment;
