import styled, { css } from 'styled-components';

export const Button = styled.button<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.primitives['s-16']};
  border-radius: ${({ theme }) => theme.spacing.primitives['s-04']};
  width: 100%;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.colors['button-list']};
  cursor: pointer;

  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      background: ${theme.colors['layer-brand-strong']};
      box-shadow: inset 0px 0px 0px 1px ${theme.colors['border-brand']};
      pointer-events: none;
    `}
`;

export const TextContent = styled.span`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-family: ${({ theme }) => theme.typography.family};
`;

export const Title = styled.span`
  font: ${({ theme }) => theme.typography['label-medium']};
`;
