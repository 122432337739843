import React, { createContext, useState } from 'react';

import { Notification, Snackbar } from '@involve-software/uikit';

export type ToggleSnackbar = (data: Omit<SnackbarState, 'open'>) => void;

export const SnackbarContext = createContext<{
  toggleSnackbar?: ToggleSnackbar;
}>({});

export interface SnackbarState {
  open: boolean;
  type: React.ComponentProps<typeof Notification>['variant'];
  title?: string;
  message?: string;
}

const SnackbarProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    type: 'success',
  });

  const toggleSnackbar: ToggleSnackbar = (data) => {
    setSnackbar({
      ...snackbar,
      open: true,
      ...data,
    });
  };

  return (
    <SnackbarContext.Provider value={{ toggleSnackbar }}>
      {children}

      <Snackbar
        open={snackbar.open}
        onOpenChange={(open) => setSnackbar({ ...snackbar, open })}
      >
        <Notification title={snackbar.title} variant={snackbar.type}>
          {snackbar.message}
        </Notification>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
