import React, { useContext } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useLoaderData } from 'react-router-dom';

import type { PaymentDetailsLoaderData } from 'modules/payment/data/loader-data';
import { PaymentContext } from 'modules/payment/layout';

import * as Styled from './index.styled';
import PaymentDetailsLayout from './layout';

const PaymentDetails = () => {
  const paymentDetails = useLoaderData() as PaymentDetailsLoaderData;

  const session = useContext(PaymentContext);

  const payways = paymentDetails.payways;
  const initialPayway = (
    payways?.find((payway) => payway.currency === session.currency) ??
    payways?.[0]
  )?.name;

  const methods = useForm<Record<string, string>>({
    defaultValues: {
      payway: initialPayway,
      ...session.addOns,
    },
  });

  return (
    <PaymentDetailsLayout showMethodsLink={!session.payway}>
      <FormProvider {...methods}>
        <Styled.DetailsForm payways={paymentDetails.payways} />
      </FormProvider>
    </PaymentDetailsLayout>
  );
};

export default PaymentDetails;
