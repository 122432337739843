import { Language } from 'data/i18n';

import { i18nInstance } from './i18n';

export const changeLanguage = async (lang: Language) => {
  window.history.pushState(
    null,
    '',
    window.location.pathname.replace(/\/[^/]+/, `/${lang}`) +
      window.location.search
  );

  await i18nInstance.changeLanguage(lang);
};
