import React from 'react';

import { useTheme } from '@involve-software/uikit';

import * as Styled from './index.styled';

interface BlockchainOptionProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  title: string;
  isSelected: boolean;
}

const BlockchainOption: React.FC<BlockchainOptionProps> = ({
  title,
  isSelected,
  ...props
}) => {
  const { icons } = useTheme();

  return (
    <Styled.Button $isSelected={isSelected} {...props}>
      <Styled.TextContent>
        <Styled.Title>{title}</Styled.Title>
      </Styled.TextContent>
      {isSelected && <icons.logo.component />}
    </Styled.Button>
  );
};

export default BlockchainOption;
