import React from 'react';

import { Card, List } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import type { CryptoPaymentSession } from 'api/crypto/payment';

import * as Styled from './index.styled';

interface InvoiceCardProps {
  shopName: CryptoPaymentSession['shopName'];
}

export const InvoiceCard: React.FC<InvoiceCardProps> = ({ shopName }) => {
  const { t } = useTranslation('crypto');

  return (
    <Styled.InvoiceCard>
      <Styled.Heading>
        <Card.HeadingTitle>
          <Styled.Title>{t('details.invoice.title')}</Styled.Title>
        </Card.HeadingTitle>
      </Styled.Heading>
      <Styled.Content>
        <List>
          <Styled.RecieverItem>
            <Styled.RecieverLabel>
              {t('details.invoice.receiver')}
            </Styled.RecieverLabel>
            <List.Content>{shopName}</List.Content>
          </Styled.RecieverItem>
        </List>
      </Styled.Content>
    </Styled.InvoiceCard>
  );
};

export default InvoiceCard;
