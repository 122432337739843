import React from 'react';

import { useTheme } from '@involve-software/uikit';

import StatusLabel from 'modules/result/components/StatusLabels';

const ErrorStatusLabel: React.FC<
  Omit<React.ComponentProps<typeof StatusLabel>, 'image'>
> = (props) => {
  const { images } = useTheme();

  return <StatusLabel image={<images.statuses.fail.component />} {...props} />;
};

export default ErrorStatusLabel;
