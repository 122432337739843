import { Card, List } from '@involve-software/uikit';

import styled from 'styled-components';

import { down } from 'utils/breakpoints';

export const InvoiceCard = styled(Card)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.primitives['s-20']};
`;

export const Heading = styled(Card.Heading)`
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
`;

export const Title = styled(Card.Title)`
  font-size: 18px;
`;

export const Content = styled(Card.Content)`
  padding-top: ${({ theme }) => theme.spacing.primitives['s-24']};
`;

export const RecieverItem = styled(List.Item)`
  gap: ${({ theme }) => theme.spacing.primitives['s-24']};
  font: ${({ theme }) => theme.typography['label-medium']};
`;

export const RecieverLabel = styled(List.Label)`
  min-width: 130px;

  ${down('md')} {
    min-width: auto;
    font: ${({ theme }) => theme.typography['label-small']};
  }
`;
