import styled from 'styled-components/macro';
import { down } from 'utils/breakpoints';

export const QPayments = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-16']};
  margin: 0 auto;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.primitives['s-20']};
  margin-top: ${({ theme }) => theme.spacing.primitives['s-32']};

  ${down('sm')} {
    flex-direction: column;
  }
`;
