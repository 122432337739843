import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ErrorCard from 'components/Errors/ErrorCard';

import { coreCodeTKeys } from '../data';

const ErrorPage = () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const coreCode = searchParams.get('core_code');

  const params = Object.fromEntries(
    [...searchParams.entries()].filter(([key]) => key !== 'lng')
  );

  const { t } = useTranslation('errors');

  const coreCodeTKey = coreCode && coreCodeTKeys[coreCode];
  const coreCodeText = coreCodeTKey
    ? t(`core.${coreCodeTKey}`, params)
    : t('wrongParams.description');

  return (
    <ErrorCard label={t('common:error')}>
      {code === '2' && coreCodeText}
      {code === '5' && t('response.unavailableForLegalReasons')}
    </ErrorCard>
  );
};

export default ErrorPage;
