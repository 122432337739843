import React, { useState } from 'react';

import { Dialog, useTheme } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';

import type { CryptoPaymentInfo } from 'api/crypto/info';

import * as SideInfoStyled from '../index.styled';

import * as Styled from './index.styled';

interface ManualAlertProps {
  manualInfo: CryptoPaymentInfo;
}

const ManualAlert: React.FC<ManualAlertProps> = ({ manualInfo }) => {
  const { i18n, t } = useTranslation('crypto');

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const { icons } = useTheme();

  return (
    <SideInfoStyled.InfoAlert title="FAQ">
      <Styled.ManualAlertTrigger onClick={() => setInfoDialogOpen(true)}>
        <icons.infodesk.component />{' '}
        {t('payment.infoBlocks.manual.dialog.trigger')}
      </Styled.ManualAlertTrigger>
      <Dialog
        title={t('payment.infoBlocks.manual.dialog.title')}
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        width="lg"
      >
        <div
          dangerouslySetInnerHTML={{
            __html:
              manualInfo.text[i18n.language as keyof CryptoPaymentInfo['text']],
          }}
        />
      </Dialog>
    </SideInfoStyled.InfoAlert>
  );
};

export default ManualAlert;
