import React from 'react';

import { useLoaderData } from 'react-router-dom';

import { IPaycosSession } from 'api/payment/paycos';

import InvoiceCard from './components/InvoiceCard';
import PaycosCard from './components/PaycosCard';
import * as Styled from './index.styled';

const PaycosPayment = () => {
  const {
    amount,
    card,
    cardHolder,
    currency,
    description,
    returnUrl,
    successUrl,
    paymentId,
  } = useLoaderData() as IPaycosSession;

  return (
    <Styled.PaycosPayment>
      <InvoiceCard
        id={paymentId}
        sum={amount}
        currency={currency.alias}
        description={description}
      />
      <PaycosCard
        returnUrl={returnUrl}
        successUrl={successUrl}
        card={card}
        cardHolder={cardHolder}
      />
    </Styled.PaycosPayment>
  );
};

export default PaycosPayment;
