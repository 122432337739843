import { factory, getAuthorization } from 'api/base';

import type { LocaleText } from 'types/locales';
import { getResponseCamelization } from 'utils/api';
import { RequestMethod } from 'utils/constants';

export interface PaymentAddsOnConfig {
  label: LocaleText;
  comment?: LocaleText;
  example: string;
  regex: string;
  optional: boolean;
}

export interface PaymentInfo {
  addOnsConfig: PaymentAddsOnConfig;
  payerCurrency: {
    alias: string;
    code: number;
  };
  payerFee: number;
  payerPrice: number;
  paymethodId: number;
  paymethodName: string;
  paymethodNameTranslations: Record<string, string> | null;
  infoId: number;
  manualId: number;
  warningId: number;
}

export const getPaymentCalculation = factory(
  (r) => (paymentId: string, payway: string) =>
    r<PaymentInfo>(
      RequestMethod.post,
      `/payment/calculate`,
      {
        payway,
      },
      {
        headers: getAuthorization(paymentId),
        transformResponse: getResponseCamelization([['data', 'addOnsConfig']]),
      }
    )
);
