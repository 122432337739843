import { factory, getAuthorization } from 'api/base';

import { RequestMethod } from 'utils/constants';

export interface CryptoPaymentSession {
  id: string;
  client: string;
  description: string;
  email?: string;
  extra: {
    address?: {
      address: string;
      blockchain: string;
    };
  };
  paymethodId?: string;
  payway: string;
  returnUrl: string;
  shopName: string;
  // TODO: Update logic according to type, define enum for it
  type: 0;
}

export const getCryptoPaymentSession = factory(
  (r) => (paymentId: string) =>
    r<CryptoPaymentSession>(
      RequestMethod.get,
      '/crypto/payment/session',
      undefined,
      {
        headers: getAuthorization(paymentId),
      }
    )
);

export interface CryptoAddress {
  data: {
    address: string;
    blockchain: string;
    currency: string;
  };
  error_code: number;
  message: string;
}

export interface CryptoPaymentAddressPayload {
  email: string;
  payway: string;
}

export const getCryptoPaymentAddress = factory(
  (r) => (paymentId: string, data: CryptoPaymentAddressPayload) =>
    r<CryptoAddress['data']>(RequestMethod.post, '/crypto/address', data, {
      headers: getAuthorization(paymentId),
    })
);
