import React from 'react';

import { ReactComponent as ABBIcon } from '../../assets/icons/banks/abb.svg';
import { ReactComponent as AkartIcon } from '../../assets/icons/banks/akart.svg';
import { ReactComponent as ATBbankIcon } from '../../assets/icons/banks/atb-bank.svg';
import { ReactComponent as KapitalBankIcon } from '../../assets/icons/banks/kapital-bank.svg';
import { ReactComponent as M10Icon } from '../../assets/icons/banks/m10.svg';
import { ReactComponent as RABITABANKIcon } from '../../assets/icons/banks/rabitabank.svg';

export const BANKS_ICONS: Record<string, React.ReactNode> = {
  М10: <M10Icon />,
  'ATB bank': <ATBbankIcon />,
  ABB: <ABBIcon />,
  RABITABANK: <RABITABANKIcon />,
  KapitalBank: <KapitalBankIcon />,
  Akart: <AkartIcon />,
};
