export const coreCodeTKeys: Record<string, string> = {
  1: 'paywayNotFound',
  2: 'paywayNotActive',
  3: 'paywayNotAccessible',
  4: 'tooSmallPayerPrice',
  5: 'tooLargePayerPrice',
  6: 'orderIdNotUnique',
  11: 'shopNotFound',
  12: 'shopNotActive',
  14: 'shopAccountNotActive',
  19: 'unableToCreateAccount',
  22: 'currencyNotAccessible',
  31: 'invalidSign',
};
