import React from 'react';

import App from 'components/App';

import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

Promise.all([import(`./themes/${process.env.REACT_APP_THEME}`)])
  .then(([themeModule]) => {
    const container = document.getElementById('root');
    if (container) {
      const root = createRoot(container);
      root.render(<App theme={themeModule.default} />);
    }
  })
  .catch((e) => {
    throw new Error(e as string);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
