import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ theme: any }>`
    #nprogress {
        .spinner {
            top: 29px;
        }
        .spinner-icon {
            border-top-color: ${({ theme }) => theme.colors['fill-04']};
            border-left-color: ${({ theme }) => theme.colors['fill-04']};
        }
        .bar {
            background-color: ${({ theme }) => theme.colors['fill-04']};
        }
    }
`;
