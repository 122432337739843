import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { PaymentStatus } from 'api/payment/status';

import HistoryStatusLabel from 'modules/result/components/StatusLabels/History';
import { STATUS_UPDATE_PERIOD_MS } from 'modules/result/data';
import { statusQuery } from 'modules/result/queries';

import { useToggleSnackbar } from 'utils/hooks/useToggleSnackbar';

import { ActionButton } from '../index.styled';

import * as Styled from './index.styled';

const InProgressStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { paymentId } = useParams();

  const { t } = useTranslation('result');

  const toggleSnackbar = useToggleSnackbar();

  const { data: statusData, refetch } = useQuery(
    statusQuery(
      { paymentId: paymentId as string },
      {
        onError: () =>
          toggleSnackbar({
            message: t('inProgress.errors.fetchFailed'),
            type: 'error',
          }),
      }
    )
  );
  const newStatus = statusData?.status as PaymentStatus;

  useEffect(() => {
    if (newStatus && newStatus !== PaymentStatus.IN_PROGRESS) {
      setSearchParams({ ...searchParams, ...statusData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStatus]);

  return (
    <>
      <HistoryStatusLabel label={t('inProgress.label')} />
      <Styled.StatusDescription>
        {t('inProgress.description', {
          periodSeconds: STATUS_UPDATE_PERIOD_MS / 1000,
        })}
      </Styled.StatusDescription>
      <ActionButton onClick={() => refetch()} variant="outlined" fullWidth>
        {t('inProgress.action')}
      </ActionButton>
    </>
  );
};

export default InProgressStatus;
