import { Card } from '@involve-software/uikit';

import styled from 'styled-components';

import { down } from 'utils/breakpoints';

export const StepsLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-20']};
`;

export const StepCard = styled(Card)`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.primitives['s-28']};
  position: relative;
  overflow: hidden;

  ${down('md')} {
    padding: ${({ theme }) => theme.spacing.primitives['s-24']};
  }
`;

export const Content = styled(Card.Content)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-64']};
  padding-top: 0;

  ${down('md')} {
    gap: 0;
  }
`;
