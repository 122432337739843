export function timeConvert(time: number) {
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  return [hrs, mins, secs];
}

export const formatTime = (timeArray: number[]) =>
  timeArray.map((num) => (num > 9 ? num : '0' + num));
