import { Card, Dialog } from '@involve-software/uikit';

import styled from 'styled-components';

import { down } from 'utils/breakpoints';

export const QPaymentsCard = styled(Card)`
  padding-top: ${({ theme }) => theme.spacing.primitives['s-24']};
  padding-bottom: ${({ theme }) => theme.spacing.primitives['s-36']};
  width: 100%;

  ${down('md')} {
    padding-bottom: ${({ theme }) => theme.spacing.primitives['s-48']};
  }
`;

export const QPaymentsForm = styled.form`
  width: 100%;
  max-width: 412px;
  margin: 0 auto;
`;

export const PhoneImage = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};
`;

export const Paragraph = styled.p`
  margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-24']};
  color: ${({ theme }) => theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['paragraph-medium']};
`;

export const CancelPaymentDialog = styled(Dialog)`
  width: 100%;
`;

export const CancelPaymentTitle = styled.p`
  color: ${({ theme }) => theme.colors['text-primary']};
  text-align: center;
  font: ${({ theme }) => theme.typography['heading-medium']};
  margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-28']};
`;
