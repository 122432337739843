import styled, { css } from 'styled-components';

export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dbe0e7;
  border-radius: 12px;
`;

export const ActionIcon = styled.div<{ $open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $open }) =>
    $open &&
    css`
      transform: rotate(180deg);
    `}
`;

export const ActionButton = styled.button<{ $open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  font: ${({ theme }) => theme.typography['label-small']};
  padding: ${({ theme }) => theme.spacing.primitives['s-28']};
  cursor: pointer;

  ${({ $open }) =>
    $open &&
    css`
      padding-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    `}
`;

export const Content = styled.div`
  font: ${({ theme }) => theme.typography['paragraph-x-small']};
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-28']}
    ${({ theme }) => theme.spacing.primitives['s-28']};
`;
