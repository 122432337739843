import React from 'react';

import { Outlet, useLoaderData } from 'react-router-dom';

import type { IBanksCactusPaymentSession } from 'api/payment/banks';

import InvoiceCard from 'components/InvoiceCard';

import * as Styled from './index.styled';

const CactusPayment = () => {
  const { amount, currency, description, paymentId } =
    useLoaderData() as IBanksCactusPaymentSession;

  return (
    <Styled.BanksCactusPayment>
      <InvoiceCard
        id={paymentId}
        sum={+amount}
        currency={currency.alias}
        description={description}
      />
      <Outlet />
    </Styled.BanksCactusPayment>
  );
};

export default CactusPayment;
