import React from 'react';

import { useTheme } from '@involve-software/uikit';

import { getMirroringConfigWithDefault } from 'utils/mirroring';

import * as Styled from './index.styled';

const LoadingOverlay = () => {
  const { icons } = useTheme();

  return (
    <Styled.LoadingOverlay fullScreen>
      <img
        src={getMirroringConfigWithDefault('loader', icons.loader.path)}
        alt="loader"
      />
    </Styled.LoadingOverlay>
  );
};

export default LoadingOverlay;
