import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import { mbankTransferLoader } from './loaders';
import MbankTransferPayment from './pages/MbankTransferPayment';

export default (
  <Route
    path="mbank_transfer/:paymentId"
    loader={mbankTransferLoader}
    element={<MbankTransferPayment />}
    errorElement={
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  />
);
