import libAxios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import router from 'router';

import { ResponseErrorCode } from 'data/errors';
import { getResponseCamelization } from 'utils/api';
import { RequestMethod } from 'utils/constants';
import { env } from 'utils/env';

function getStaticGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  if (process.env.NODE_ENV === 'development') {
    headers.Dev = '1';
  }

  return headers;
}

function getDynamicGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  // TODO: add after i18n implementation
  // headers.Lng = (i18nInstance && i18nInstance.language) || fallbackLng;

  return headers;
}

const axiosGlobalConfig: AxiosRequestConfig = {
  baseURL: env.REACT_APP_API_URL,
  timeout: parseInt(env.REACT_APP_REQUEST_TIMEOUT || '20') * 1000,
  headers: getStaticGlobalHeaders(),
};

axiosGlobalConfig.transformResponse = [getResponseCamelization()];
axiosGlobalConfig.paramsSerializer = {
  serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  encode: (params) => qs.parse(params),
};

const axios = libAxios.create(axiosGlobalConfig);

axios.interceptors.response.use(
  (response) => {
    if (response.data.errorCode != null && response.data.errorCode !== 0) {
      if (response.data.errorCode === ResponseErrorCode.ACCESS_DENIED) {
        const params = {
          ip: response.headers['X-Real-IP'],
          country: response.headers['X-Country-Code'],
        };

        router.navigate(`/access_denied?${qs.stringify(params)}`);
      }

      return Promise.reject(response);
    }

    if (response.data && typeof response.data.data !== 'undefined') {
      response.data = response.data.data;
    } else {
      response.data = {};
    }

    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export function request<T = any>(
  this: AxiosRequestConfig | void,
  method: RequestMethod,
  uri: string,
  data?: object,
  functionScopedConfig?: AxiosRequestConfig
): AxiosPromise<T> {
  const conf: AxiosRequestConfig = {
    method,
    url: uri,
    [method === 'get' ? 'params' : 'data']: data,
    ...this,
    ...functionScopedConfig,
  };

  const dynamicGlobalHeaders = getDynamicGlobalHeaders();

  if (conf.headers == null) {
    conf.headers = dynamicGlobalHeaders;
  } else {
    conf.headers = { ...dynamicGlobalHeaders, ...conf.headers };
  }

  return axios(conf);
}

export type RequestContext = AxiosRequestConfig | void;

export type RequestFn<Args extends any[] = any, Return = any> = (
  this: RequestContext,
  ...args: Args
) => Promise<{ data: Return }>;

export function factory<Args extends any[], Return>(
  handler: (r: typeof request) => RequestFn<Args, Return>
) {
  return function (this: RequestContext, ...rest: Args) {
    const r = request.bind(this) as typeof request; // bind looses generic

    return handler(r)(...rest);
  };
}

export function getAuthorization(token: string) {
  return { Authorization: `Bearer ${token}` };
}
