import React from 'react';

import { Button, useTheme } from '@involve-software/uikit';

import { Trans, useTranslation } from 'react-i18next';

import CopyBlock from 'components/CopyBlock';

import type { EmanatCardProps } from './index.interface';
import * as Styled from './index.styled';
import { ExpiredAlert } from './index.styled';

const EmanatCard: React.FC<EmanatCardProps> = ({
  card,
  cardHolder,
  returnUrl,
  successUrl,
}) => {
  const { t } = useTranslation('emanat');
  const { icons } = useTheme();

  return (
    <Styled.EmanatCard>
      <Styled.EmanatTitle>{t('emanat:payment')}</Styled.EmanatTitle>
      <Styled.EmanatSubtitle>{t('emanat:paymentGuide')}</Styled.EmanatSubtitle>
      <Styled.EmanatDetails>
        <CopyBlock
          copyText={card}
          label={t('emanat:details')}
          startAdornment={<icons.card.component />}
        />
        <CopyBlock
          copyText={cardHolder}
          label={t('emanat:cardOwner')}
          startAdornment={<icons.profile.component />}
        />

        <ExpiredAlert title={t('common:alerts.important')} variant="warning">
          <Trans i18nKey="emanat:expiredText">
            Payment details are only valid for <b>8 minutes!</b>
          </Trans>
        </ExpiredAlert>

        <Styled.ButtonsGroup>
          {returnUrl && (
            <Styled.ButtonLinkWrap href={returnUrl}>
              <Button variant="outlined">{t('emanat:returnButton')}</Button>
            </Styled.ButtonLinkWrap>
          )}

          {successUrl && (
            <Styled.ButtonLinkWrap href={successUrl}>
              <Button>{t('common:alreadyPaid')}</Button>
            </Styled.ButtonLinkWrap>
          )}
        </Styled.ButtonsGroup>
      </Styled.EmanatDetails>
    </Styled.EmanatCard>
  );
};

export default EmanatCard;
