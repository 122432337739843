import styled from 'styled-components';

export const QrPayment = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-12']};
  max-width: 445px;
  width: 100%;
  margin: 0 auto;
`;
