import { factory, getAuthorization } from 'api/base';

import { RequestMethod } from 'utils/constants';

export interface INcpaySession {
  amount: number;
  card: string;
  currency: {
    alias: string;
    code: number;
  };
  description: string | null;
  paymentId: string;
  returnUrl: string | null;
  successUrl: string | null;
}

export const getNcpaySession = factory(
  (r) => (session: string) =>
    r<INcpaySession>(RequestMethod.get, `/ncpay/session`, undefined, {
      headers: getAuthorization(session),
    })
);
