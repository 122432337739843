import { type LoaderFunction, redirect } from 'react-router-dom';

import { getCryptoPaymentInfo } from 'api/crypto/info';
import {
  getCryptoPaymentAddress,
  getCryptoPaymentSession,
} from 'api/crypto/payment';
import {
  MethodPayways,
  PaywayData,
  getPayway,
  getPayways,
} from 'api/crypto/payment/paymethods';

export const redirectLoader: LoaderFunction = ({ request }) => {
  const { pathname, search } = new URL(request.url);

  if (pathname.endsWith('/details') || pathname.endsWith('/payment')) {
    return;
  }

  return redirect(pathname + '/details' + search);
};

export const cryptoLoader: LoaderFunction = async ({ params }) => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const { data } = await getCryptoPaymentSession(params.paymentId);
  return data;
};

export interface CryptoDetailsLoaderData {
  paywaysData?: MethodPayways;
  paywayData?: PaywayData;
}

export const cryptoDetailsLoader: LoaderFunction = async ({
  params,
}): Promise<Response | CryptoDetailsLoaderData> => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const { data: session } = await getCryptoPaymentSession(params.paymentId);

  if (session.payway && session.email) {
    return redirect(`/crypto/payment/${params.paymentId}/payment`);
  }

  if (session.paymethodId) {
    const { data: paywaysData } = await getPayways(
      params.paymentId,
      session.paymethodId
    );

    return { paywaysData };
  } else {
    const { data: paywayData } = await getPayway(
      params.paymentId,
      session.payway
    );

    return { paywayData };
  }
};

export const paymentDetailsLoader: LoaderFunction = async ({
  request,
  params,
}) => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const { data: session } = await getCryptoPaymentSession(params.paymentId);
  const url = new URL(request.url);

  const email = url.searchParams.get('email') ?? session.email;
  const payway = url.searchParams.get('payway') ?? session.payway;

  if (!email || !payway) {
    throw new Error('Required params are missing');
  }

  const address =
    session.extra.address ??
    (await getCryptoPaymentAddress(params.paymentId, {
      email,
      payway,
    }).then(({ data }) => data));

  const { data: paywayData } = await getPayway(params.paymentId, payway);

  const [manual, warning, info] = await Promise.all([
    paywayData.manualId
      ? getCryptoPaymentInfo('manual', paywayData.manualId, params.paymentId)
      : undefined,
    paywayData.warningId
      ? getCryptoPaymentInfo('warning', paywayData.warningId, params.paymentId)
      : undefined,
    paywayData.infoId
      ? getCryptoPaymentInfo('info', paywayData.infoId, params.paymentId)
      : undefined,
  ]);

  return {
    address,
    email,
    payway: paywayData,
    infoText: {
      manual: manual?.data,
      warning: warning?.data,
      info: info?.data,
    },
    isPrefilled: !!session.email && !!session.payway,
  };
};
