import React from 'react';

import { useTheme } from '@involve-software/uikit';

import * as Styled from './index.styled';

interface AlertProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: React.ReactNode;
  variant: 'info' | 'warning';
  children: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({
  title,
  variant,
  children,
  ...props
}) => {
  const { icons } = useTheme();

  const variantIcons: Record<string, React.ReactNode> = {
    info: <icons.statuses.info.component />,
    warning: <icons.statuses.warning.component />,
  };

  return (
    <Styled.Alert $variant={variant} {...props}>
      <Styled.AlertIcon>{variantIcons[variant]}</Styled.AlertIcon>
      <Styled.AlertText>
        <Styled.AlertTitle>{title}</Styled.AlertTitle>
        <Styled.AlertDescription>{children}</Styled.AlertDescription>
      </Styled.AlertText>
    </Styled.Alert>
  );
};

export default Alert;
