import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import CactusBanksPaymentLayout from './layout';
import { selectMethodLoader, selectMethodRedirectLoader } from './loaders';
import SelectMethodPayment from './pages/SelectMethodPayment';
import SelectMethodSelection from './pages/SelectMethodSelection';

export default (
  <Route
    id="selectMethod"
    path="select_method/:paymentId"
    loader={selectMethodLoader}
    element={<CactusBanksPaymentLayout />}
    errorElement={
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  >
    <Route path="" loader={selectMethodRedirectLoader} />
    <Route path="methods" element={<SelectMethodSelection />} />
    <Route path="payment" element={<SelectMethodPayment />} />
  </Route>
);
