import { factory, getAuthorization } from 'api/base';

import { RequestMethod } from 'utils/constants';

export interface Payway {
  addOnsConfig: {
    a: number;
    b: number;
  };
  alias: 'card_rub';
  currency: string;
  feeConfig: {
    fix: number;
    min: number;
    percent: number;
  };
  feePartConfig: {
    fixPart: number;
    percentPart: number;
  };
  id: number;
  infoId: number;
  isActive: true;
  manualId: number;
  maxAmount: number;
  minAmount: number;
  name: string;
  scannerUrl: string;
  warningId: number;
}

export interface MethodPayways {
  id: number;
  name: string;
  payways: Payway[];
}

export const getPayways = factory(
  (r) => (paymentId: string, paymethodId: number | string) =>
    r<MethodPayways>(
      RequestMethod.get,
      `/crypto/paymethods/${paymethodId}/payways`,
      undefined,
      {
        headers: getAuthorization(paymentId),
      }
    )
);

export interface PaywayData extends Payway {
  paymethodId: number;
  paymethodName: string;
}

export const getPayway = factory(
  (r) => (paymentId: string, paywayId: number | string) =>
    r<PaywayData>(RequestMethod.get, `/crypto/payways/${paywayId}`, undefined, {
      headers: getAuthorization(paymentId),
    })
);
