import React from 'react';

import type { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

import ErrorCard from 'components/Errors/ErrorCard';

import { ERROR_CODE_TKEYS, ServiceErrorCode } from 'data/errors';

const ErrorBoundary = () => {
  // TODO: Provide valid type
  const error = useRouteError() as AxiosResponse<any, any>;

  const { t } = useTranslation('errors');

  const errorTKey = ERROR_CODE_TKEYS[error?.data.errorCode as ServiceErrorCode];

  return (
    <ErrorCard label={t('common:error')}>
      {t(errorTKey ? `service.${errorTKey}` : 'errors:wrongParams.description')}
    </ErrorCard>
  );
};

export default ErrorBoundary;
