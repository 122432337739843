import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { type PaymentCreateRedirect, createPayment } from 'api/payment/create';
import type { PaymentMethod } from 'api/payment/payways';

import SelfSubmittingForm from 'components/selfSubmittingForm';

import MethodOption from 'modules/payment/components/MethodOption';
import { fiatCurrencyAlias } from 'modules/payment/data/currency';

import { useToggleSnackbar } from 'utils/hooks/useToggleSnackbar';
import { getPmImgSrc } from 'utils/media';

import { PaymentContext } from '../../layout';

import * as Styled from './index.styled';

const PaymentMethods: React.FC = () => {
  const payMethods = useLoaderData() as PaymentMethod[];

  const session = useContext(PaymentContext);

  const navigate = useNavigate();
  const { paymentId: paymentToken } = useParams();
  const [paymentCreateData, setPaymentCreateData] =
    useState<PaymentCreateRedirect | null>(null);

  const { t } = useTranslation('methods');

  const toggleSnackbar = useToggleSnackbar();

  const handleClickOnPayMethod = async ({
    id,
    calculationNeeded,
  }: Pick<PaymentMethod, 'id' | 'calculationNeeded'>) => {
    if (calculationNeeded) {
      navigate({
        pathname: `/payment/${paymentToken}/details`,
        search: `?paymethodId=${id}`,
      });
    } else {
      try {
        const { data: paymentData } = await createPayment(paymentToken!, {
          form: session.addOns,
        });

        setPaymentCreateData(paymentData);
      } catch {
        toggleSnackbar({
          message: t('details.errors.creation'),
          type: 'error',
        });
      }
    }
  };

  return (
    <div>
      <Styled.Title>{t('options.title')}</Styled.Title>
      <Styled.Options>
        {payMethods.map(
          ({
            id,
            name,
            nameTranslations,
            payerPrice,
            paywayCurrency,
            calculationNeeded,
          }) => (
            <MethodOption
              key={`paymethod-${id}`}
              name={name}
              nameTranslations={nameTranslations}
              icon={<img alt="name" src={getPmImgSrc(id)} />}
              amount={payerPrice}
              currency={fiatCurrencyAlias[paywayCurrency]}
              onClick={() => handleClickOnPayMethod({ id, calculationNeeded })}
            />
          )
        )}
        {paymentCreateData && (
          <SelfSubmittingForm
            method={paymentCreateData.method}
            action={paymentCreateData.url}
            data={paymentCreateData.data}
          />
        )}
      </Styled.Options>
    </div>
  );
};

export default PaymentMethods;
