import { factory, getAuthorization } from 'api/base';

import { RequestMethod } from 'utils/constants';

import { PaymentStatus } from './status';

export interface ICardTransferSession {
  amount: number;
  card: string;
  cardHolder?: string;
  currency: {
    alias: string;
    code: number;
  };
  status: PaymentStatus;
  paymentId: string;
  description?: string;
  failedUrl?: string;
  returnUrl?: string;
  successUrl?: string;
  expiresAt?: string;
  expiresIn?: number; // in seconds
}

export const getCardTransferSession = factory(
  (r) => (session: string) =>
    r<ICardTransferSession>(
      RequestMethod.get,
      `/card_transfer/session`,
      undefined,
      {
        headers: getAuthorization(session),
      }
    )
);
