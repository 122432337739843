import { useQuery } from '@tanstack/react-query';

import { calculationQuery, infoQuery } from 'modules/payment/queries';

const useInfo = (paymentId: string, payway: string) => {
  const { data: calculationData } = useQuery(
    calculationQuery({
      paymentId: paymentId as string,
      payway: payway,
    })
  );

  const manual = useQuery({
    ...infoQuery({
      paymentId,
      type: 'manual',
      infoId: calculationData?.manualId as number,
    }),
    enabled: !!calculationData?.manualId,
  });
  const info = useQuery({
    ...infoQuery({
      paymentId,
      type: 'info',
      infoId: calculationData?.infoId as number,
    }),
    enabled: !!calculationData?.infoId,
  });
  const warning = useQuery({
    ...infoQuery({
      paymentId,
      type: 'warning',
      infoId: calculationData?.warningId as number,
    }),
    enabled: !!calculationData?.warningId,
  });

  return { manual, info, warning };
};

export default useInfo;
