import React from 'react';

import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';

export function createCustomLinkComponent(
  LinkComponent: React.ComponentClass<React.HTMLProps<HTMLAnchorElement>>
) {
  return React.forwardRef<HTMLAnchorElement, LinkProps>(
    ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
      const href = useHref(to);
      const handleClick = useLinkClickHandler(to, {
        replace,
        state,
        target,
      });

      return (
        <LinkComponent
          {...rest}
          href={href}
          onClick={(event) => {
            onClick?.(event);

            if (!event.defaultPrevented) {
              handleClick(event);
            }
          }}
          ref={ref as any}
          target={target}
        />
      );
    }
  );
}
