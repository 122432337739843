import { Maybe } from 'monet';
import * as R from 'ramda';

import maxpaykzFavicon from 'assets/images/maxpaykz/favicon.png';
import maxpaykzLogoDesktop from 'assets/images/maxpaykz/logo-desk.svg';
import maxpaykzLogoMobile from 'assets/images/maxpaykz/logo-mob.png';

type TMirroringConfig = {
  logoDesktop: string;
  logoMobile: string;
  favicon: string;
  loader: string;
};

const mirroringConfig: Record<string, TMirroringConfig> = {
  ['test-pay.maxpaykz.com']: {
    logoDesktop: maxpaykzLogoDesktop,
    logoMobile: maxpaykzLogoMobile,
    favicon: maxpaykzFavicon,
    loader: maxpaykzFavicon,
  },
  ['pay.maxpaykz.com']: {
    logoDesktop: maxpaykzLogoDesktop,
    logoMobile: maxpaykzLogoMobile,
    favicon: maxpaykzFavicon,
    loader: maxpaykzFavicon,
  },
  // ['localhost']: {
  //   logoDesktop: maxpaykzLogoDesktop,
  //   logoMobile: maxpaykzLogoMobile,
  //   favicon: maxpaykzFavicon,
  //   loader: maxpaykzFavicon,
  // },
};

export const getMirroringConfig = <K extends keyof TMirroringConfig>(key: K) =>
  Maybe.fromEmpty(mirroringConfig[window.location.hostname])
    .flatMap((config) => Maybe.fromEmpty(R.path([key], config)))
    .orUndefined();

export const getMirroringConfigWithDefault = <K extends keyof TMirroringConfig>(
  key: K,
  defaultValue: string
) => Maybe.fromEmpty(getMirroringConfig(key)).getOrElse(defaultValue);
