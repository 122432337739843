import React from 'react';

import { Button, Card } from '@involve-software/uikit';

import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as BinancePayLogo } from 'assets/images/qr-paymethods/binancepay.svg';
import { getMedia, useMediaQuery } from 'utils/breakpoints';

import * as Styled from './index.styled';

interface BinancePayCardProps {
  amount: number;
  currency: string;
  invoiceId: string;
  payMethodAlias: string;
  qr: string;
  urlMobile: string;
  urlWeb: string;
  returnUrl?: string;
  successUrl?: string;
}

const BinancePayCard: React.FC<BinancePayCardProps> = ({
  amount,
  currency,
  invoiceId,
  qr,
  urlMobile,
  urlWeb,
  returnUrl,
  successUrl,
}) => {
  const { t } = useTranslation('binancePay');

  const isDesktop = useMediaQuery(getMedia('md', 'min'));

  return (
    <Styled.QrCard>
      <Styled.QrHeading>
        <Card.HeadingTitle>
          <Styled.QrTitle>
            {isDesktop ? (
              <>
                {t('common:invoice')} №{invoiceId}
              </>
            ) : (
              t('binancePay:payment')
            )}
          </Styled.QrTitle>
        </Card.HeadingTitle>
        {isDesktop && (
          <Styled.QrHeadingActions>
            <Styled.Amount>
              {amount} {currency}
            </Styled.Amount>
            <Styled.AmountLabel>{t('binancePay:amount')}</Styled.AmountLabel>
          </Styled.QrHeadingActions>
        )}
      </Styled.QrHeading>
      <Styled.QrMethodLogo>
        <BinancePayLogo />
      </Styled.QrMethodLogo>
      <Styled.BinanceMobileAppLink href={urlMobile} rel="noopener noreferrer">
        <Button variant="outlined" icon={<PhoneIcon />}>
          {t('binancePay:binanceButton')}
        </Button>
      </Styled.BinanceMobileAppLink>

      <Styled.BinanceWebApp>
        <Trans i18nKey="binancePay:binanceWebApp">
          Or go to the
          <a href={urlWeb} rel="noopener noreferrer">
            exchange&apos;s website
          </a>
        </Trans>
      </Styled.BinanceWebApp>

      <Styled.QrCode>
        <img src={`${decodeURIComponent(qr)}`} alt="qr-code" />
        <p>{t('binancePay:description')}</p>
      </Styled.QrCode>

      <Styled.BinancePayButtonGroup>
        {successUrl && (
          <Styled.ContainedButtonLink href={successUrl}>
            <Button fullWidth icon={<CheckmarkIcon />}>
              {t('common:alreadyPaid')}
            </Button>
          </Styled.ContainedButtonLink>
        )}
        {returnUrl && (
          <Styled.TextButtonLink href={returnUrl} rel="noopener noreferrer">
            <Button fullWidth variant="text">
              {t('common:returnButton')}
            </Button>
          </Styled.TextButtonLink>
        )}
      </Styled.BinancePayButtonGroup>
    </Styled.QrCard>
  );
};

export default BinancePayCard;
