import styled from 'styled-components/macro';
import { down } from 'utils/breakpoints';

export const ContactSupportBlock = styled.div`
  max-width: 502px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.primitives['s-32']};
  border-radius: ${({ theme }) => theme.radius['radius-modal']};
  border: 1px solid ${({ theme }) => theme.colors['border-main']};
  background-color: ${({ theme }) => theme.colors['layer-01']};
  padding: ${({ theme }) => theme.spacing.primitives['s-24']};
  ${({ theme }) => theme.spacing.primitives['s-28']};
  ${down('sm')} {
    padding: 0;
    gap: ${({ theme }) => theme.spacing.primitives['s-28']};
    background-color: transparent;
    border: none;
  }
`;

export const MediaContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-28']};
  align-items: center;
  & > svg {
    width: 120px;
    min-width: 120px;
    height: auto;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-08']};
  text-align: center;
  & > p {
    font: ${({ theme }) => theme.typography['heading-medium']};
    color: ${({ theme }) => theme.colors['text-primary']};
    margin: 0;
  }
  & > span {
    font: ${({ theme }) => theme.typography['paragraph-medium']};
    color: ${({ theme }) => theme.colors['text-secondary']};
  }
`;

export const ButtonsContainer = styled.div`
  button:nth-child(1) {
    ${down('sm')} {
      display: none;
    }
  }
  button:nth-child(2) {
    display: none;
    ${down('sm')} {
      display: flex;
    }
  }
`;
