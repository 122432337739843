import type { UseQueryOptions } from '@tanstack/react-query';

import {
  PaymentInfo as PaymentCalculationInfo,
  getPaymentCalculation,
} from 'api/payment/calculation';
import {
  PaymentInfo,
  type PaymentInfoType,
  getPaymentInfo,
} from 'api/payment/info';

import type { CalculationError } from './data/errors';

export interface CalculationErrorData {
  data: {
    message: string;
    data: {
      code: CalculationError;
      data: {
        minAmount?: number;
        maxAmount?: number;
      };
    };
  };
}

// TODO: Create factory function for such queries
export const calculationQuery = (
  queryData: { paymentId: string; payway: string },
  options?: UseQueryOptions<PaymentCalculationInfo | null, CalculationErrorData>
): UseQueryOptions<PaymentCalculationInfo | null, CalculationErrorData> => ({
  queryKey: ['calculation', queryData.payway],
  queryFn: async () => {
    if (!queryData.payway) return null;

    const { data } = await getPaymentCalculation(
      queryData.paymentId as string,
      queryData.payway
    );
    return data;
  },

  // TODO: May be global options for all queries
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});

export const infoQuery = (
  queryData: { paymentId: string; type: PaymentInfoType; infoId: number },
  options?: UseQueryOptions<PaymentInfo>
): UseQueryOptions<PaymentInfo> => ({
  queryKey: ['info', queryData.type, queryData.infoId],
  queryFn: async () => {
    const { data } = await getPaymentInfo(
      queryData.paymentId,
      queryData.type,
      queryData.infoId
    );
    return data;
  },
  // TODO: May be global options for all queries
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});
