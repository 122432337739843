import type { UseQueryOptions } from '@tanstack/react-query';

import {
  ICardTransferSession,
  getCardTransferSession,
} from 'api/payment/cardTransfer';
import {
  IMbankTransferSession,
  getMbankTransferSession,
} from 'api/payment/mbankTransfer';
import { IQPaymentsSession, getQPaymentsSession } from 'api/payment/qPayments';
import {
  SelectMethodPaySession,
  getSelectMethodPaySession,
} from 'api/payment/selectMethod';
import { PaymentStatusData, getPaymentStatus } from 'api/payment/status';

import { STATUS_UPDATE_PERIOD_MS } from './data';

export const statusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<PaymentStatusData>
): UseQueryOptions<PaymentStatusData> => ({
  queryKey: ['calculation', queryData.paymentId],
  queryFn: async () => {
    const { data } = await getPaymentStatus(queryData.paymentId as string);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});

export const qPaymentsStatusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<IQPaymentsSession>
): UseQueryOptions<IQPaymentsSession> => ({
  queryKey: ['calculation', queryData.paymentId],
  queryFn: async () => {
    const { data } = await getQPaymentsSession(queryData.paymentId);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});

export const cardTransferStatusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<ICardTransferSession>
): UseQueryOptions<ICardTransferSession> => ({
  queryKey: ['calculation', queryData.paymentId],
  queryFn: async () => {
    const { data } = await getCardTransferSession(queryData.paymentId);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});

export const mbankTransferStatusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<IMbankTransferSession>
): UseQueryOptions<IMbankTransferSession> => ({
  queryKey: ['calculation', queryData.paymentId],
  queryFn: async () => {
    const { data } = await getMbankTransferSession(queryData.paymentId);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});

export const selectMethodTransferStatusQuery = (
  queryData: {
    paymentId: string;
  },
  options?: UseQueryOptions<SelectMethodPaySession>
): UseQueryOptions<SelectMethodPaySession> => ({
  queryKey: ['calculation', queryData.paymentId],
  queryFn: async () => {
    const { data } = await getSelectMethodPaySession(queryData.paymentId);
    return data;
  },
  refetchInterval: STATUS_UPDATE_PERIOD_MS,
  refetchOnWindowFocus: false,
  retry: false,
  ...options,
});
