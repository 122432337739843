import { getResponseCamelization } from 'utils/api';
import { PaymentSessionType, RequestMethod } from 'utils/constants';

import { factory, getAuthorization } from '../base';

interface PaymentRedirect {
  method: string;
  url: string;
  data: Record<string, string>;
}

export interface PaymentSession {
  id: string;
  type: PaymentSessionType;
  shopId: number;
  shopName: string;
  shopOrderId: string;
  amount: number;
  currency: number;
  description?: string;
  payway?: string;
  addOns: Record<string, string>;
  extra: {
    redirect?: PaymentRedirect;
  };
}

export const getPaymentSession = factory(
  (r) => (paymentId: string) =>
    r<PaymentSession>(RequestMethod.get, '/payment/session', undefined, {
      headers: getAuthorization(paymentId),
      transformResponse: getResponseCamelization([
        ['data', 'extra', 'redirect', 'data'],
      ]),
    })
);
